import {
  Button as NextUIButton,
  type ButtonProps as NextUIButtonProps,
} from '@nextui-org/button';
import { forwardRef } from 'react';
import Tooltip, { type TooltipProps } from './tooltip';

export interface ButtonProps extends NextUIButtonProps {
  tooltip?: TooltipProps;
  kind?: 'primary' | 'secondary' | 'tertiary' | 'icon-only' | 'custom';
}

const Button = forwardRef(
  ({ size = 'md', kind = 'primary', isIconOnly = false, ...rest }: ButtonProps, ref: any) => {
    if (kind === 'primary') {
      rest.className = `bg-primary-500 hover:bg-primary-600 font-semibold text-white  aria-expanded:bg-primary-600 ${rest.className} `;
    }

    if (kind === 'custom') {
      rest.className = `${rest.className}`;
    }

    if (kind === 'secondary') {
      rest.className = `bg-grey-100 hover:bg-grey-200 font-semibold text-grey-900 ${rest.className} aria-expanded:bg-grey-200`;
    }

    if (kind === 'tertiary') {
      rest.color = 'default';
      rest.variant = 'bordered';
      rest.className = `bg-white border-1 hover:bg-grey-200 font-semibold text-grey-900 ${rest.className} aria-expanded:bg-grey-200`;
    }

    if (kind === 'icon-only') {
      rest.color = 'default';
      rest.variant = 'bordered';
      rest.className = `min-w-9 w-9 px-0 border hover:bg-grey-200 ${rest.className} aria-expanded:bg-grey-200`;
    }

    if (isIconOnly) {
      rest.className = `min-w-9 w-9 px-0 ${rest.className}`;
    }

    if (rest.isDisabled) {
      rest.className = `opacity-30 cursor-not-allowed ${rest.className}`;
    }

    const sizes = {
      sm: 'h-8',
      md: 'h-9.5',
      lg: 'h-11',
    }[size];

    const component = (
      <NextUIButton
        hover
        disableRipple
        isIconOnly={isIconOnly}
        ref={ref}
        {...rest}
        className={`rounded-md gap-1 antialiased ${sizes} px-4 py-0 ${rest.className} aria-expanded:opacity-100 aria-expanded:scale-100`}
      />
    );

    return <Tooltip {...rest.tooltip}>{component}</Tooltip>;
  }
);

export default Button;
