import React, { useState } from 'react';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'onFocus' | 'onBlur'> {
  isValid?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  size?: 'lg' | 'md';
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addOnLeft?: React.ReactNode;
  addOnRight?: React.ReactNode;
  handleFocus?: (isFocus: boolean) => void;
}

function Input(
  {
    isValid = false,
    isInvalid = false,
    placeholder = '',
    size = 'md',
    disabled = false,
    readOnly = false,
    className = '',
    value,
    onChange,
    addOnLeft,
    addOnRight,
    handleFocus,
    width,
    ...props
  }: InputProps,
  ref: React.Ref<HTMLInputElement>
) {
  const [isFocused, setIsFocused] = useState(false);
  const getHeight = () => {
    switch (size) {
      case 'lg':
        return 'h-9.5';
      case 'md':
      default:
        return 'h-8';
    }
  };
  const getFocusedClass = () => {
    if (disabled) return 'border border-grey-600';
    if (isInvalid) {
      return 'border border-red-500 shadow-[0_0_0_1px_inset] shadow-pure-red-500';
    }
    if (isValid) {
      return 'border border-blue-500 shadow-[0_0_0_1px_inset] shadow-green-300';
    }
    return isFocused ? 'border border-blue-500 shadow-[0_0_0_1px_inset] shadow-blue-500' : 'border border-grey-400';
  };

  const getBgColor = () => {
    switch (readOnly) {
      case true:
        return 'bg-grey-50';
      default:
        return 'bg-white';
    }
  };

  const onFocus = (isFocus: boolean) => {
    handleFocus?.(isFocus);
    setIsFocused(isFocus);
  };
  return (
    <div
      className={`
      ${className}
        px-2 rounded-md text-sm flex gap-2 w-full sh
      ${disabled ? 'opacity-40' : ''}
    ${getBgColor()} ${getHeight()} ${getFocusedClass()}`}
    >
      {addOnLeft && (
        <div className={`flex items-center ${size === 'lg' ? 'ml-2' : ''}`}>
          {addOnLeft}
        </div>
      )}
      <input
        {...props}
        ref={ref}
        style={{ width }}
        className={`outline-none bg-transparent w-full h-full ${
          readOnly ? 'text-grey-900' : ''
        }`}
        onBlur={() => onFocus(false)}
        onFocus={() => onFocus(true)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled || readOnly}
      />
      {addOnRight && (
        <div className={`flex items-center ${size === 'lg' ? 'mr-2' : ''}`}>
          {addOnRight}
        </div>
      )}
    </div>
  );
}

export default React.forwardRef(Input);
