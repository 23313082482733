// Mozilla/5.0 ZoomWebKit/537.36 (KHTML, like Gecko) ZoomApps/1.0
import { contextHeader, getAppContext } from '@zoom-app/cipher';

export const isZoomApp = () => {
  if (typeof window !== 'undefined') {
    return window.navigator.userAgent.match(/(Zoom)/);
  }
  return false;
};

export const zoomAppBasePath = process.env.NEXT_PUBLIC_ZM_BASE_PATH;

export const redirectToZoomApp = (router) => {
  if (isZoomApp()) {
    router.push(zoomAppBasePath);
  }
};

export const getRunningContext = (headers) => {
  if (!headers[contextHeader]) {
    return {};
  }
  return getAppContext(headers[contextHeader]);
};

export const isMeetingContext = (context) => {
  if (context?.match(/^(inMeeting|inWebinar|meeting|webinar)$/)) {
    return true;
  };
  return false;
};

export const getErrorMessage = (error) => {
  let errorMessage;
  if (error?.reason) {
    errorMessage = error.reason;
  } else {
    errorMessage = 'Something went wrong. Please try again.';
  }
  return errorMessage;
};

export const signupLink = `${process.env.NEXT_PUBLIC_URL}signup?utm_source=zoomapp`;

export const sandboxLink = process.env.NEXT_PUBLIC_SANDBOX_URL;

export const isHostOrCoHost = (role) => role.match(/^(host|co-host|coHost)$/);
