import { StreamDataContext } from '@components/context/StreamContext';
import { TeamsDataContext } from '@components/context/TeamsContext';

import { useContext, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

const Zoom = dynamic(
  () => import('./Zoom'),
  { ssr: false }
);

const HiddenZoomComponent = () => {
  const { zoom } = useContext(StreamDataContext);
  const { currentTeam } = useContext(TeamsDataContext);

  const [start, setstart] = useState(false);

  useEffect(() => {
    if (zoom?.type === 'join') {
      setstart(true);
    } else if (zoom?.type === 'leave') {
      setstart(false);
    }
  }, [zoom]);

  return (
    <>
      {start && (
        <Zoom
          meetingId={zoom.meetingId}
          password={zoom.pwd}
          liveStreamId={zoom.liveStreamId}
          tk={zoom.tk}
          setRender={() => setstart(false)}
          botName={currentTeam.billing_account.bot_name}
        />
      )}
    </>
  );
};

export default HiddenZoomComponent;
