/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

import { useCelloJwtToken } from '@lib/hooks/useCelloJwtToken';
import { useGetUserEmail } from '@lib/hooks/useGetUserEmail';
import { useRouter } from 'next/router';
import { TeamsDataContext } from './context/TeamsContext';

declare global {
  interface Window {
    CelloAttribution: (arg: string) => any;
  }
}

interface CelloProps {
  children: any
}

const CelloComponent = ({ children }: CelloProps) => {
  const { entity } = useCelloJwtToken();
  const userData = useGetUserEmail();

  const router = useRouter();

  const { setCelloUcc, celloUcc } = useContext(TeamsDataContext);

  const loadScript = (src: any) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'module';
    script.async = true;

    script.onload = () => {
      document.body.removeChild(script);
    };

    document.body.appendChild(script);
  };

  useEffect(() => {
    const productId = Cookies.get('cello-product-id');
    const ucc = Cookies.get('cello-referral');

    if (productId && ucc && (router.pathname === '/signup' || router.pathname === '/login' || router.pathname === '/')) {
      if (router.query.productId !== productId || router.query.ucc !== ucc) {
        const updatedQuery = {
          ...router.query,
          productId,
          ucc,
        };
        router.replace({ pathname: router.pathname, query: updatedQuery }, undefined, { shallow: true });
      }
    }
    loadScript(process.env.NEXT_PUBLIC_CELLO_SCRIPT);
    loadScript(process.env.NEXT_PUBLIC_CELLO_ATTRIBUTES_SCRIPT);
  }, [router]);

  useEffect(() => {
    if (entity?.token === undefined || !userData?.email) return;

    // @ts-expect-error
    window.cello = window.cello || { cmd: [] };
    // @ts-expect-error
    window.cello.cmd.push(async function (cello) {
      try {
        await cello.boot({
          productId: process.env.NEXT_PUBLIC_CELLO_PRODUCT_ID,
          token: entity.token,
          language: 'en',
          productUserDetails: {
            firstName: userData?.email,
            lastName: userData?.email,
            fullName: userData?.email,
            email: userData?.email
          }
        });
        if (!celloUcc) { setCelloUcc(window?.CelloAttribution('getReferral')); }
      } catch (error) {
        console.error('Failed to boot cello:', error);
      }
    });
  }, [entity?.token, userData, router?.query]);

  return <>{children}</>;
};

export default CelloComponent;
