/* eslint-disable @typescript-eslint/ban-ts-comment */
import '../styles/globals.css';

import WithAuth from '@components/Auth/WithAuth';
import { StreamDataProvider } from '@components/context/StreamContext';
import { TeamsDataProvider } from '@components/context/TeamsContext';
import { ZoomDataProvider } from '@zoom-app/components/contexts/ZoomContext';
import { getLayout } from '@components/Layout/LayoutConfiguration';
import Head from 'next/head';
import HiddenZoomComponent from '@components/HiddenZoomComponent';
import ErrorBoundaryWrapper from '@components/Errors/ErrorBoundaryWrapper';
import { ThemeProvider } from 'next-themes';
import GoogleTagManager from '@components/common/GoogleTagManager';
import { NextUIProvider } from '@nextui-org/react';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CelloComponent from '@components/celloComponent';
import { GoogleOAuthProvider } from '@react-oauth/google';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps, router }) {
  const layout = getLayout(router.pathname);
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Head>
        <link rel="favicon" href="/images/streamAlive-favicon.png" />
        <title>StreamAlive</title>
        {!['/login', '/signup', '/sandbox/interactions-generator', '/sandbox/audience-engagement-ai'].includes(router.pathname) && <meta name="viewport" content="width=1440" />}
      </Head>
      <ThemeProvider attribute="class" enableSystem={false} enableColorScheme={false} forcedTheme={Component.theme || 'light'}>
        <NextUIProvider>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundaryWrapper>
              <TeamsDataProvider>
                <StreamDataProvider>
                  <ZoomDataProvider>
                    <WithAuth router={router}>
                      <GoogleTagManager>
                        <CelloComponent>
                          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_AUTH_GOOGLE_ID}>
                            {layout({ children: <Component {...pageProps} /> })}
                            <HiddenZoomComponent />
                            <ToastContainer position='bottom-left' theme='colored' />
                          </GoogleOAuthProvider>
                        </CelloComponent>
                      </GoogleTagManager>
                    </WithAuth>
                  </ZoomDataProvider>
                </StreamDataProvider>
              </TeamsDataProvider>
            </ErrorBoundaryWrapper>
          </QueryClientProvider>
        </NextUIProvider>
      </ThemeProvider>
    </div>
  );
}
export default MyApp;
