import { getAuth } from '@services/identity.service';
import { getUserAccountById } from '@services/youtube-platform.service';
import { useEffect, useState } from 'react';

export const useGetUserEmail = () => {
  const [userData, setUserData] = useState<any>({});

  const getData = async () => {
    try {
      const tem = await getUserAccountById();

      if (tem?.entity) { setUserData(tem?.entity); }
    } catch (error) {
      console.log('Failed to fetch token ');
    }
  };

  useEffect(() => {
    const auth = getAuth();

    if (auth?.id) {
      getData();
    }
  }, []);
  return userData;
};
