import { getAuth } from '@services/identity.service';
import { logsToAwsCloudWatch } from '@services/logger.service';
import React, {
  createContext, useEffect, useState, useReducer
} from 'react';

import { LinkLibraryReducer, defaultState } from '@components/Features/LinkLibraryReducer';

export const StreamDataContext = createContext();
export const StreamDataProvider = ({ children }) => {
  const defaultId = 'a17fa8c2-2eaf-407e-9d7c-ca714298a513';
  const [connectionTime, setConnectionTime] = useState({ start: null, end: null, platform: null });
  const [firestoreUnsub, setFirestoreUnsub] = useState({});
  const [unsubOverlayComments, setUnsubOverlayComments] = useState({});
  const [overlayCommentsTimerId, setOverlayCommentsTimerId] = useState('');
  const [newQuestion, setNewQuestion] = useState(null);
  const [mapBox, setMapBox] = useState('');
  const [hiddenData, setHiddenData] = useState({});
  const [zoom, setZoom] = useState(null);
  const [particularMap, setParticularMap] = useState({});
  const [oneUserOneVote, setOneUserOneVote] = useState(false);
  const [zoomMeetingStatus, setZoomMeetingStatus] = useState({ type: 'join', isInMeeting: false });
  const [overrideDismissStatus, setOverrideDismissStatus] = useState(false);
  const [showApiError, setShowApiError] = useState(false);
  const [featureQuestion, setFeatureQuestion] = useState({ id: defaultId, title: '', options: [] });
  const [resultPollData, setResultPollData] = useState({
    id: defaultId, comments: [], title: '', type: ''
  });
  const [resultMapData, setResultMapData] = useState({ id: defaultId, title: '', features: [] });
  const [resultWordCloudData, setResultWordCloudData] = useState({ id: defaultId, comments: [], title: '' });
  const [resultWheelData, setResultWheelData] = useState({ id: defaultId, comments: [], title: '' });
  const [nextQuestion, setNextQuestion] = useState([]);
  const [featureSettings, setFeatureSettings] = useState({});
  const [featureBrandColours, setFeatureBrandColours] = useState({});
  const [featureId, setFeatureId] = useState();
  const [showUserGuide, setShowUserGuide] = useState(false);
  const [errMessage, setErrMessage] = useState('Something went wrong. Please try again.');
  const [interactionQuestion, setInteractionQuestion] = useState({});
  const [resultTtData, setResultTtData] = useState({});
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [preventRoute, setPreventRoute] = useState();
  const [route, setRoute] = useState();
  const [showUserName, setShowUserName] = useState(true);
  const [intervalId, setIntervalId] = useState(0);
  const [questionPage, setQuestionPage] = useState(0);
  const [prevMapDataById, setPrevMapDataById] = useState({});
  const [liveStreamId, setLivestreamId] = useState(null);
  const [sessionHistory, setSessionHistory] = useState({});
  const [isCreateOrEditPresentation, setIsCreateOrEditPresentation] = useState(false);
  const [rosPayload, setRosPayload] = useState({});
  const [isLinkActive, setIsLinkActive] = useState(false);

  const [linkLibraryState, dispatchLinkLibrary] = useReducer(
    LinkLibraryReducer,
    defaultState
  );

  useEffect(() => {
    if (connectionTime.end) {
      const { start, end, platform } = connectionTime;
      const duration = (end - start) / 1000;

      const auth = getAuth();
      const body = {
        logMessage: `Time taken for ${platform} bot to connect: ${duration} s`,
        logData: { userId: auth?.id || '' }
      };

      logsToAwsCloudWatch(body);
    }
  }, [connectionTime]);

  return (
    <StreamDataContext.Provider value={{
      firestoreUnsub,
      setFirestoreUnsub,
      unsubOverlayComments,
      setUnsubOverlayComments,
      overlayCommentsTimerId,
      setOverlayCommentsTimerId,
      newQuestion,
      setNewQuestion,
      mapBox,
      setMapBox,
      hiddenData,
      setHiddenData,
      zoom,
      setZoom,
      setParticularMap,
      particularMap,
      oneUserOneVote,
      setOneUserOneVote,
      zoomMeetingStatus,
      setZoomMeetingStatus,
      overrideDismissStatus,
      setOverrideDismissStatus,
      showApiError,
      setShowApiError,
      featureQuestion,
      setFeatureQuestion,
      resultPollData,
      setResultPollData,
      resultMapData,
      setResultMapData,
      resultWordCloudData,
      setResultWordCloudData,
      nextQuestion,
      setNextQuestion,
      featureSettings,
      setFeatureSettings,
      featureBrandColours,
      setFeatureBrandColours,
      featureId,
      setFeatureId,
      showUserGuide,
      setShowUserGuide,
      errMessage,
      setErrMessage,
      interactionQuestion,
      setInteractionQuestion,
      resultWheelData,
      setResultWheelData,
      resultTtData,
      setResultTtData,
      selectedQuestion,
      setSelectedQuestion,
      preventRoute,
      setPreventRoute,
      route,
      setRoute,
      showUserName,
      setShowUserName,
      intervalId,
      setIntervalId,
      questionPage,
      setQuestionPage,
      connectionTime,
      setConnectionTime,
      linkLibraryState,
      dispatchLinkLibrary,
      prevMapDataById,
      setPrevMapDataById,
      liveStreamId,
      setLivestreamId,
      sessionHistory,
      setSessionHistory,
      isCreateOrEditPresentation,
      setIsCreateOrEditPresentation,
      rosPayload,
      setRosPayload,
      isLinkActive,
      setIsLinkActive
    }}>
      {children}
    </StreamDataContext.Provider>
  );
};
