import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const app = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_BUCKET,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
});

const db = getFirestore();

if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
  console.log('Using Firestore emulator');
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
}

const realTimeDb = getDatabase(app);
const storage = getStorage();

export default db;

export { storage, realTimeDb };
