import EmptyLayout from './EmptyLayout';
import PresenterLayout from './PresenterLayout';

const LayoutConfig = [
  {
    path: '/',
    layout: PresenterLayout
  },
  {
    path: '/landing',
    layout: PresenterLayout
  },
  {
    path: '/colors',
    layout: PresenterLayout
  },
  {
    path: '/settings',
    layout: PresenterLayout
  },
  {
    path: '/youtube/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/teams/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/accountLinked',
    layout: PresenterLayout
  },
  {
    path: '/admin/dashboard',
    layout: PresenterLayout
  },
  {
    path: '/tutorial',
    layout: PresenterLayout
  },
  {
    path: '/welcome',
    layout: PresenterLayout
  },
  {
    path: '/auth/login',
    layout: PresenterLayout
  },
  {
    path: '/account',
    layout: PresenterLayout
  },
  {
    path: '/authorize',
    layout: PresenterLayout
  },
  {
    path: '/streams/[streamId]/prepare',
    layout: PresenterLayout
  },
  {
    path: '/stats',
    layout: PresenterLayout
  },
  {
    path: '/samplestats',
    layout: PresenterLayout
  },
  {
    path: '/stats/[streamId]',
    layout: PresenterLayout
  },
  {
    path: '/samplestats/[streamId]',
    layout: PresenterLayout
  },
  {
    path: '/presentations',
    layout: PresenterLayout
  },
  {
    path: '/presentations/[templateId]',
    layout: PresenterLayout
  },
  {
    path: '/zoom/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/meet/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/twitch/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/vimeo/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/webex/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/linkedin/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/error',
    layout: PresenterLayout
  },
  {
    path: '/ipa/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/other-online-events/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/my-teams/[teamId]',
    layout: PresenterLayout
  },
  {
    path: '/my-teams/invitations/[id]',
    layout: PresenterLayout
  },
  {
    path: '/appSumoPricingPlan',
    layout: PresenterLayout
  },
  {
    path: '/pricing',
    layout: PresenterLayout
  },
  {
    path: '/hybrid/[platformId]',
    layout: PresenterLayout
  },
  {
    path: '/accessibility',
    layout: PresenterLayout
  },
  {
    path: '/zoom-app',
    layout: PresenterLayout
  },
  {
    path: '/zoom-app/meeting',
    layout: PresenterLayout
  },
  {
    path: '/zoom-app/login',
    layout: PresenterLayout
  },
  {
    path: '/zoom-app/signup',
    layout: PresenterLayout
  },
  {
    path: '/zoom-app/landing',
    layout: PresenterLayout
  },
  {
    path: '/sessions',
    layout: PresenterLayout
  },
  {
    path: '/templates',
    layout: PresenterLayout
  },
  {
    path: '/interaction-links',
    layout: PresenterLayout
  },
  {
    path: '/themes',
    layout: PresenterLayout
  },
  {
    path: '',
    layout: EmptyLayout
  }
];

const getLayout = (path) => {
  const config = LayoutConfig.find((layout) => path === layout.path);
  if (config) return config.layout;
  return EmptyLayout;
};

export { getLayout };
export default getLayout;
